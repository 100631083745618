<template>
  <div>
    <b-loading is-full-page :active="carregando" can-cancel></b-loading>

    <AppTitulo titulo="Validação Empresas" />

    <section class="section">
      <div class="container is-fullhd">
        <FiltroValidacao />

        <div v-if="!exibirTabela" class="panel">
          <div class="panel-block is-justify-content-center">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhuma Empresa.</p>
              </div>
            </section>
          </div>
        </div>

        <div v-if="exibirTabela" class="panel">
          <div class="panel-block">
            <b-field grouped group-multiline>
              <div class="control">
                <b-switch v-model="somenteSelecionados">Exibir somente selecionados</b-switch>
              </div>
            </b-field>
          </div>

          <div class="panel-block">
            <b-table
              checkable
              backend-pagination
              class="table is-fullwidth"
              default-sort-direction="ASC"
              :data="dadosEmpresas"
              :checked-rows.sync="selecionados"
              :mobile-card="false"
            >
              <b-table-column
                sortable
                centered
                field="criado"
                label="Data de solicitação"
                width="200"
                v-slot="props"
              >
                {{ props.row.criado | formatarData }}
              </b-table-column>

              <b-table-column
                sortable
                field="empresa.denominacaoSocial"
                label="Empresa"
                width="200"
                v-slot="props"
              >
                <router-link
                  :to="{
                    name: 'ReviewItem',
                    params: { validacao: props.row.id },
                  }"
                >
                  {{ props.row.empresa.denominacaoSocial }}
                </router-link>
              </b-table-column>

              <b-table-column
                sortable
                field="usuario.nome"
                label="Usuário"
                width="200"
                v-slot="props"
              >
                {{ props.row.usuario.nome }}
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="status"
                label="Status"
                width="60"
                v-slot="props"
              >
                <span :class="badgeStatus(props.row)">
                  {{ textoStatus(props.row) }}
                </span>
              </b-table-column>

              <template v-slot:footer>
                <AppPagination
                  :data-filters="dataFilters"
                  :total="total"
                  @change-page="page => filtrarEmpresasPendentes({ page })"
                  @change-per-page="perPage => alterarPorPaginaPendentes(perPage)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { funcoes } from '@/utils/enumerados';
import AppTitulo from '@/components/AppTitulo';
import AppPagination from '@/components/AppPagination';
import { formatarData } from '@/utils/format';
import FiltroValidacao from './FiltroValidacao';

export default {
  name: 'ValidacaoEmpresa',
  components: {
    AppTitulo,
    AppPagination,
    FiltroValidacao,
  },
  data() {
    return {
      selecionados: [],
      somenteSelecionados: false,
      exibirOpcoes: true,
      funcoes,
    };
  },
  computed: {
    ...mapGetters({
      empresas: 'obterEmpresasPendentes',
      funcao: 'getFunction',
      carregando: 'obterLoadingEmpresasPendentes',
      total: 'obterTotalEmpresasPendentes',
      dataFilters: 'obterDadosFiltrosEmpresasPendentes',
    }),
    exibirTabela() {
      return this.empresas.length;
    },
    dadosEmpresas() {
      return this.somenteSelecionados ? this.selecionados : this.empresas;
    },
  },
  filters: {
    formatarData,
  },
  methods: {
    ...mapActions(['filtrarEmpresasPendentes', 'alterarPorPaginaPendentes']),
    textoStatus(row) {
      if (row.empresa && row.empresa.status === 'BLOQUEADA') return row.empresa.status;
      if (row.status === 'PENDENTE') return row.status;
      if (row.status === 'VALIDA') return 'VÁLIDA';
      return 'INVÁLIDA';
    },
    badgeStatus(row) {
      return {
        tag: true,
        'is-success': row.status === 'VALIDA',
        'is-warning': row.status === 'PENDENTE',
        'is-danger':
          (row.empresa && row.empresa.status === 'BLOQUEADA') || row.status === 'INVALIDA',
      };
    },
  },
};
</script>

<style lang="scss">
.table-item {
  background-color: #fafafa;
}

.is-centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.field.is-grouped .field {
  flex-shrink: 1;
}
</style>
