<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-title-fullhd">
          <div class="level validation-level">
            <div class="level-left">
              <div>
                <h1 class="title">Empresa</h1>
              </div>
            </div>

            <div
              class="tm-1"
              v-if="
                empresaValidacao.empresa &&
                  ['BLOQUEADA', 'HABILITADA'].indexOf(empresaValidacao.empresa.status) > -1
              "
            >
              <b-field>
                <b-switch
                  false-value="HABILITADA"
                  size="is-large"
                  true-value="BLOQUEADA"
                  type="is-danger"
                  :value="empresaValidacao.empresa.status"
                  @input="status => updateBlockedCompany()"
                  >{{
                    empresaValidacao.empresa.status === 'BLOQUEADA' ? 'Desbloquear' : 'Bloquear'
                  }}</b-switch
                >
              </b-field>
            </div>

            <div
              v-if="exibirEmpresaInvalida || exibirEmpresaValida || blockedCompany"
              class="tag-container tm-1"
            >
              <transition v-if="exibirEmpresaInvalida" name="fade" mode="out-in">
                <div class="tag-invalida">
                  <h3 style="font-weight: bold">Empresa Inválida</h3>
                </div>
              </transition>

              <transition v-if="exibirEmpresaValida" name="fade" mode="out-in">
                <div class="tag-valida">
                  <h3 style="font-weight: bold">Empresa Válida</h3>
                </div>
              </transition>

              <transition v-if="blockedCompany" name="fade" mode="out-in">
                <div class="tag-invalida">
                  <h3 style="font-weight: bold">Empresa Bloqueada</h3>
                </div>
              </transition>
            </div>

            <div v-if="exibirValidarEmpresa" class="level-end has-text-centered tm-1">
              <button
                class="acoes button is-primary is-inverted is-outlined"
                :class="{ ' is-loading': validarLoading }"
                @click.prevent.stop="exibeModal"
              >
                Validar Empresa
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-narrow">
            <MenuSecundario titulo="Perfil" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      :onCancel="resetarLoading"
      @justificate="atualizarValidacao"
    >
      <AppJustification v-if="justificativa" failText="Invalidar" successText="Validar" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MenuSecundario from '@/components/MenuSecundario';
import AppJustification from '../../../components/AppJustification';

export default {
  name: 'ReviewItem',
  components: {
    MenuSecundario,
    AppJustification,
  },
  data() {
    const loading = {
      general: false,
    };

    return {
      loading,
      loaded: false,
      justificativa: false,
      validarLoading: false,
    };
  },
  computed: {
    ...mapState('companies', ['company', 'companyHistory']),
    ...mapGetters({
      empresaValidacao: 'obterEmpresaValidacao',
    }),
    blockedCompany() {
      return (
        this.loaded &&
        this.empresaValidacao.empresa &&
        this.empresaValidacao.empresa.status === 'BLOQUEADA'
      );
    },
    pendente() {
      return this.empresaValidacao.status === 'PENDENTE';
    },
    exibirValidarEmpresa() {
      return (
        this.loaded &&
        this.company.id === 1 &&
        (this.companyHistory.status === 'INABILITADA' ||
          this.companyHistory.status === 'PRIMEIRA_VALIDACAO') &&
        this.pendente
      );
    },
    exibirEmpresaValida() {
      return this.loaded && this.empresaValidacao.status === 'VALIDA' && !this.blockedCompany;
    },
    exibirEmpresaInvalida() {
      return this.loaded && this.empresaValidacao.status === 'INVALIDA' && !this.blockedCompany;
    },
    menu() {
      return [
        {
          titulo: 'Dados Cadastrais',
          rota: 'ViewCompanyData',
          parametros: { id: this.companyHistory.id },
        },
        {
          titulo: 'Junta Comercial/Cartório',
          rota: 'ViewCompanyRegistry',
          parametros: { id: this.companyHistory.id },
        },
        {
          titulo: 'Enquadramento',
          rota: 'ViewCompanyType',
          parametros: { id: this.companyHistory.id },
        },
        {
          titulo: 'Documentação',
          rota: 'ViewCompanyDocuments',
          parametros: { id: this.companyHistory.id },
        },
        {
          titulo: 'Usuários',
          rota: 'ViewCompanyUsers',
          parametros: { id: this.companyHistory.id },
        },
      ];
    },
  },
  methods: {
    ...mapActions('companies', ['fetchCompanyHistory', 'updateCompany']),
    ...mapActions(['buscarEmpresaValidacao', 'buscarEmpresaValidacoes', 'validarEmpresa']),
    exibeModal() {
      this.justificativa = true;
    },
    async atualizarValidacao({ justification, state }) {
      const data = {
        status: state === 'success' ? 'VALIDA' : 'INVALIDA',
        justificativa: justification || 'Empresa aprovada.',
      };

      this.validarLoading = true;
      try {
        await this.validarEmpresa(data);
        this.$alerta(`Empresa ${state === 'success' ? 'validada' : 'invalidada'}`, 'is-success');
      } catch (error) {
        this.$alerta('Erro ao validar empresa', 'is-danger');
      }
      this.validarLoading = false;
      this.$refs.justificationModal.close();
    },
    resetarLoading() {
      this.validarLoading = false;
    },
    async updateBlockedCompany() {
      this.loading.general = true;
      try {
        await this.updateCompany({
          companyId: this.empresaValidacao.empresa.id,
          status: this.blockedCompany ? 'HABILITADA' : 'BLOQUEADA',
        });
        await this.buscarEmpresaValidacao(this.$route.params.validacao);

        this.$alerta(
          `Empresa ${this.blockedCompany ? 'bloqueada' : 'desbloqueada'}`,
          `${this.blockedCompany ? 'is-danger' : 'is-success'}`,
        );
      } catch (error) {
        this.$alerta(
          `Erro ao ${this.blockedCompany ? 'desbloquear' : 'bloquear'} empresa`,
          'is-danger',
        );
      } finally {
        this.loading.general = false;
      }
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await this.buscarEmpresaValidacao(this.$route.params.validacao);
      await this.fetchCompanyHistory(this.$route.params.validacao);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
      this.loaded = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.is-centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.tag-container {
  text-align: end;
  width: 200px;
}

.tag-valida {
  background-color: #48a84e;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  -webkit-box-pack: center;
}

.tag-invalida {
  background-color: #f00a0a !important;
  color: #fff !important;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  -webkit-box-pack: center;
}

.validation-level {
  display: flex;
}

@media (min-width: 300px) and (max-width: 768px) {
  .margin-item {
    margin-top: min(1.5rem);
  }

  .tm-1 {
    margin-top: 2rem;
  }

  .validation-level {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
